<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 예산년도 -->
          <c-datepicker
            label="예산년월"
            name="year"
            type="month"
            :range="true"
            v-model="searchParam.years"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-select
            type="search"
            :comboItems="budgetCatItems"
            itemText="codeName"
            itemValue="code"
            label="카테고리"
            name="budgetCat"
            v-model="searchParam.budgetCat">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="KOSTL_CD"
            itemText="codeName"
            itemValue="code"
            name="kostl"
            label="Cost Center"
            v-model="searchParam.kostl"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="KSTAR_CD"
            itemText="codeName"
            itemValue="code"
            name="kstar"
            label="계정"
            v-model="searchParam.kstar"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-select
            type="search"
            codeGroupCd="ZCOMAT0008_CD"
            itemText="codeName"
            itemValue="code"
            name="zcomat0008Cd"
            label="업무구분"
            v-model="searchParam.zcomat0008Cd"
          ></c-select>
        </div>
        <div class="float-right gridbtntop2">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-12" style="background:#fff;">
        <apexchart 
          ref="chart1" 
          type="line"
          :height="chart.height" 
          :options="chart.chartOptions" 
          :series="chart.series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
import { toThousandFilter } from '@/utils/filter';
export default {
  name: 'safety-health-year-budget',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        plantCd: '',
        years: [],
        budgetCat: null,
        kostl: null,
        kstar: null,
        actFlag: null,
        zcomat0008Cd: null,
      },
      budgetCatItems: [
        { code: '1', codeName: '일반예산' },
        { code: '2', codeName: '투자예산' },
      ],
      actFlagItems: [
        { code: 'P', codeName: '예산' },
        { code: 'A', codeName: '실적' },
      ],
      editable: true,
      isSave: false,
      saveUrl: '',
      listUrl: '',
      saveData: [],
      chart: {
        height: '600',
        chartOptions: {
          title: {
            text: '기간별 예산실적 현황'
          },
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          xaxis: {
            categories: []
          },
          yaxis: {
            title: {
              text: '금액(SUM)'
            },
            labels: {
              formatter: function(value) {
                var val = Math.abs(value)
                if (val >= 1000) {
                  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                return val
              }
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
        },
        series: [],
        chartWidth: '80%',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.budget.year.list.url + '/graph';
      this.searchParam.years = [this.$comm.getThisYear() + '-01', this.$comm.getThisYear() + '-12'] 
      this.getList();
    },
    getList() {
      if (this.searchParam.years.length == 2) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          let diffmonth = this.$comm.getMonthdiff(this.$comm.moment(this.searchParam.years[0]), this.$comm.moment(this.searchParam.years[1]))
          
          let _dataP = [];
          let _dataA = [];
          let _start = this.$comm.moment(this.searchParam.years[0]);
          for(var i=0; i<=diffmonth; i++) {
            let _month = this.$comm.getAddMonth2(_start, i);
            this.chart.chartOptions.xaxis.categories.push(_month)
            let _pIdx = this.$_.findIndex(_result.data, {zdate: _month, actFlagName: '예산' });
            if ( _pIdx > -1) {
              _dataP.push(_result.data[_pIdx].amt | toThousandFilter)
            } else {
              _dataP.push(0)
            }
            let _aIdx = this.$_.findIndex(_result.data, {zdate: _month, actFlagName: '실적' });
            if ( _aIdx > -1) {
              _dataA.push(_result.data[_aIdx].amt | toThousandFilter)
            } else {
              _dataA.push(0)
            }
          }
          this.chart.series = [{
            name: "예산",
            data: _dataP
          },
          {
            name: "실적",
            data: _dataA
          }];

          console.log(this.chart.series)

          this.chart.chartWidth = '100%';
          this.$refs['chart1'].refresh();
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '조회기간을 선택하세요', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
  }
};
</script>
